<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Order Information') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="6">
          <b-row>
            <b-col mb="8">
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Customer') }}
                </span>
                <span class="w-50 font-weight-bolder">
                  {{ $t('da') }}
                </span>
              </b-col>
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Placed by') }}
                </span>
                <span class="w-50 font-weight-bolder">
                  {{ $t('da') }}
                </span>
              </b-col>
            </b-col>
            <b-col mb="4" />
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col mb="8">
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Agent') }}
                </span>
                <span class="w-50 font-weight-bolder">
                  {{ $t('User 3 test') }}
                </span>
              </b-col>
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('PO/Event Ref.') }}
                </span>
                <span class="w-50 font-weight-bolder">
                  {{ $t('PO38338832 test') }}
                </span>
              </b-col>
            </b-col>
            <b-col mb="4" />
          </b-row>
        </b-col>
        <b-col
          v-for="field of ['warehouseNotes', 'orderNotes']"
          :key="field"
          md="6"
          class="mt-1"
        >
          <component
            :is="RentalSalesPickFields[field].type"
            style="width: 100%"
            v-bind="getProps(field, RentalSalesPickFields)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'

export default {
  name: 'RentalSalesOrderInformation',
  components: { BCol, BRow },
  setup() {
    const { RentalSalesPickFields, MODULE_NAME } = config()
    return {
      RentalSalesPickFields,
      MODULE_NAME,
      getProps,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
