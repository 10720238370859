<template>
  <div>
    <quote-status-bar
      :quote-status-bar="quoteStatusBar"
      class="ml-1"
    />
    <b-row class="mt-2">
      <b-col cols="6">
        <rental-sales-dispatch />
      </b-col>
      <b-col cols="6">
        <rental-sales-return />
      </b-col>
    </b-row>
    <rental-sales-order-information class="mt-2" />
    <rental-sales-order-items class="mt-2" />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import mainConfig from '@/views/main/warehouse/config'
import { BCol, BRow } from 'bootstrap-vue'
import RentalSalesDispatch from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesDispatch.vue'
import RentalSalesReturn from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesReturn.vue'
import RentalSalesOrderInformation from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesOrderInformation.vue'
import RentalSalesOrderItems from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesOrderItems.vue'

export default {
  name: 'RentalSalesForm',
  components: {
    RentalSalesOrderItems,
    RentalSalesOrderInformation,
    RentalSalesReturn,
    RentalSalesDispatch,
    QuoteStatusBar,
    BCol,
    BRow,
  },
  setup() {
    const { transferFields, MODULE_NAME, MODULE_NAME_CLONE } = config()
    const { quoteStatusBar } = mainConfig()
    return {
      transferFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      quoteStatusBar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
