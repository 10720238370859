import { LBoxIcon, LTruckIcon } from '@core/components/feather-icon/customIcons'

export default function config() {
  const MODULE_NAME = 'warehouse'
  const orderStatus = [
    {
      icon: 'LQuoteIconFill',
    },
    {
      icon: 'LSignatureIconFill',
    },
    {
      icon: 'LCreditCardIconFill',
    },
    {
      icon: 'LTruckIcon',
    },
    {
      icon: 'LWarehouseIcon',
    },
    {
      icon: 'LBoxSentIcon',
    },
  ]

  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
  ]
  const quoteStatusBar = [
    {
      icon: 'LPickIcon',
      title: 'Pick',
      iconSize: '26',
    },
    {
      icon: 'LBoxIcon',
      title: 'Pack',
      iconSize: '26',
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '26',
    },
  ]
  return {
    quoteStatusBar,
    orderStatus,
    orderStatesAndStatuses,
    MODULE_NAME,
  }
}
