import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'warehouse/rental-sales'
const Actions = crudActions(endpoint)
// eslint-disable-next-line no-unused-vars
const getRentalSalesOrders = ({ commit }, id) => axios.get(`${endpoint()}/${id}/pick`)

export default {
  ...Actions,
  getRentalSalesOrders,
}
